<template>
  <v-card>
    <v-card-title>Pellet-編集</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-form class="ma-6">
        <v-form class="ma-6">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="pelletID"> Pellet ID<span style="color: red">*</span> </label>
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-text-field
                id="pellet_id"
                v-model="$v.PelletForm.pellet_id.$model"
                outlined
                hide-details="auto"
                dense
                placeholder="PelletID 英数字only"
                readonly
                :error-messages="idErrors"
                :counter="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="pelletName"> Pellet名(日本語)<span style="color: red">*</span> </label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="pellet_name"
                v-model="$v.PelletForm.pellet_name_jpn.$model"
                outlined
                hide-details="auto"
                dense
                placeholder="pellet 日本語名"
                :error-messages="nameErrorsJpn"
                :counter="100"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="pelletName"> Pellet名(中国語)<span style="color: red">*</span> </label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="pellet_name"
                v-model="$v.PelletForm.pellet_name_chn.$model"
                outlined
                hide-details="auto"
                dense
                placeholder="pellet 中国語名"
                :error-messages="nameErrorsChn"
                :counter="100"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="pelletName"> Pellet名(英語)<span style="color: red">*</span> </label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="pellet_name"
                v-model="$v.PelletForm.pellet_name_eng.$model"
                outlined
                hide-details="auto"
                dense
                placeholder="pellet 中国語名"
                :error-messages="nameErrorsEng"
                :counter="100"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(PelletForm)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-form>
    </template>
  </v-card>
</template>

<script>
import {
  mdiCameraOutline,
} from '@mdi/js'
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    url: null,
    submitStatus: false,
    icon: {
      mdiCameraOutline,
    },
    OriginalForm: {
    },

    PelletForm: {
      pellet_id: '',
      pellet_name_jpn: '',
      pellet_name_chn: '',
      pellet_name_eng: '',

    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  validations: {
    PelletForm: {
      pellet_id: {
        required,
        maxLength: maxLength(20),
      },
      pellet_name_jpn: {
        required,
        maxLength: maxLength(100),
      },
      pellet_name_chn: {
        required,
        maxLength: maxLength(100),
      },
      pellet_name_eng: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    ...mapState('pelletStore', ['selectedPellet']),

    idErrors() {
      const errors = []
      if (!this.$v.PelletForm.pellet_id.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_id.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_id.required && errors.push('必須項目')

      return errors
    },

    nameErrorsJpn() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_jpn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_jpn.maxLength && errors.push('Name must be at most 100 characters long')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_jpn.required && errors.push('必須項目')

      return errors
    },

    nameErrorsChn() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_chn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_chn.maxLength && errors.push('Name must be at most 100 characters long')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_chn.required && errors.push('必須項目')

      return errors
    },

    nameErrorsEng() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_eng.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_eng.maxLength && errors.push('Name must be at most 100 characters long')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_eng.required && errors.push('必須項目')

      return errors
    },

  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('pelletStore', ['loadPellet', 'editPellet']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadPellet(this.$route.params.id)
        .then(() => {
          this.PelletForm = this.selectedPellet
          console.log('pelletForm:', this.selectedPellet, this.PelletForm)
          this.OriginalForm = JSON.stringify(this.PelletForm)
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.setOverlayStatus(false)
        })
    },
    cancelClick() {
      if (this.OriginalForm !== JSON.stringify(this.PelletForm)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }

      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/pellet-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submit(PelletForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.OriginalForm !== JSON.stringify(this.PelletForm)) {
          this.submitStatus = true
          this.setOverlayStatus(true)
          const pellet = {
            ...PelletForm,
          }

          this.editPellet(pellet)
            .then(() => {
              this.setOverlayStatus(false)
              this.jumpToListPage()
            })
            .catch(error => {
              this.setOverlayStatus(false)
              if (error.response) {
                this.apierror.status = 'error'
                this.apierror.code = error.response.data.code
                this.apierror.messages = error.response.data.message
              }
            }).finally(() => {
              this.submitStatus = false
            })
        } else {
          this.jumpToListPage()
        }
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
